import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'landing-page',
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss',
})
export class LandingPageComponent {
  constructor(public authService: AuthService) {}

  login() {
    this.authService.loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}/app`,
      },
    });
  }

  signup() {
    this.authService.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        prompt: 'login',
      },
    });
  }

  // This was the animation script used, consider re-building this in angular, probably through animations directly
  // <script>
  // (function () {
  //     const doc = document.documentElement
  //
  //     doc.classList.remove('no-js')
  //     doc.classList.add('js')
  //
  //     // Reveal animations
  //     if (document.body.classList.contains('has-animations')) {
  //       /* global ScrollReveal */
  //       const sr = window.sr = ScrollReveal()
  //
  //       sr.reveal('.hero-title, .hero-paragraph, .hero-cta', {
  //         duration: 1000,
  //         distance: '40px',
  //         easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
  //         origin: 'bottom',
  //         interval: 150
  //       })
  //
  //       sr.reveal('.feature, .pricing-table', {
  //         duration: 600,
  //         distance: '40px',
  //         easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
  //         interval: 100,
  //         origin: 'bottom',
  //         viewFactor: 0.5
  //       })
  //
  //       sr.reveal('.feature-extended-image', {
  //         duration: 600,
  //         scale: 0.9,
  //         easing: 'cubic-bezier(0.5, -0.01, 0, 1.005)',
  //         viewFactor: 0.5
  //       })
  //     }
  //   }())
  // </script>
}
