import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  AuthHttpInterceptor,
  AuthModule,
  provideAuth0,
} from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InitializationService } from './services/initialization/initialization.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { LandingPageModule } from './components/pages/landing-page/landing-page.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    BrowserAnimationsModule,
    LandingPageModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      disableConsoleLogging: false,
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    provideAuth0({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        audience: environment.auth0.audience,
        redirect_uri: `${window.location.origin}/app`,
      },
      httpInterceptor: {
        allowedList: [`${environment.apiHost}/api/*`],
      },
      cacheLocation: 'localstorage',
    }),
    InitializationService,
    {
      provide: APP_INITIALIZER,
      useFactory: bootstrapApp,
      deps: [InitializationService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

function bootstrapApp(init: InitializationService) {
  return async () => {
    await init.initializeApp();
  };
}
